import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';

export const BaseLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <BaseLayout>
      <Header />
      {children}
      <Footer />
    </BaseLayout>
  );
};

export default Layout;
