import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconArrowCircleRight({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon width="64" height="65" viewBox="0 0 64 65" fill="none"  sx={sx} {...props}>
<ellipse cx="31.8964" cy="32.516" rx="31.8964" ry="32.3217" fill="currentColor"/>
<path d="M22.9658 32.0906H41.6784M41.6784 32.0906L32.3221 23.1596M41.6784 32.0906L32.3221 41.0216" stroke="url(#paint0_linear_648_1912)" stroke-linecap="square"/>
<defs>
<linearGradient id="paint0_linear_648_1912" x1="23.3092" y1="23.1596" x2="40.4672" y2="41.8195" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#F7F7F7"/>
</linearGradient>
</defs>

    </SvgIcon>
  );
}
