export const fontCss = () => `
  @font-face {
    font-family: 'ABC Diatype Semi-Mono';
    font-style: normal;
    font-weight: 400;
    src: url("${process.env.PUBLIC_URL}/fonts/ABCDiatypeSemi-Mono-Regular.otf");
  }
  @font-face {
    font-family: 'ABC Diatype Semi-Mono';
    font-style: italic;
    font-weight: 400;
    src: url("${process.env.PUBLIC_URL}/fonts/ABCDiatypeSemi-Mono-RegularItalic.otf");
  }
  @font-face {
    font-family: 'ABC Diatype Semi-Mono';
    font-style: normal;
    font-weight: 500;
    src: url("${process.env.PUBLIC_URL}/fonts/ABCDiatypeSemi-Mono-Medium.otf");
  }
  @font-face {
    font-family: 'ABC Diatype Semi-Mono';
    font-style: italic;
    font-weight: 500;
    src: url("${process.env.PUBLIC_URL}/fonts/ABCDiatypeSemi-Mono-MediumItalic.otf");
  }
`;
