import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { px } from '../../theme/box';
import { SectionTitle } from '../../components/styled';

export function MediaPartner() {
  const theme = useTheme();

  return (
      <Stack
        sx={{
          zIndex: 2,
          position: 'relative',
          background: theme.colors.functional.container.white
        }}
        alignItems="center"
        justifyContent="center"
        px={px}
        pt={{ xs: 5.375, sm: 10.25 }}
        pb={{ xs: 20.5, sm: 20.5, md: 30.75 }}
      >
        <Typography variant="h5" height={{ xs: '82px' }} component={SectionTitle} flexDirection="row" alignItems="center">Media Partner</Typography>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          mt={{ xs: 0, sm: 0, md: 10.25 }}
          component={Link}
          href="https://mm.therollup.co"
          sx={{background: theme.colors.functional.container.white, border: "1px solid #EBE8E8", py:2, px: 6, position:"relative"}}
          minWidth={{xs: "calc(80vw / 3)", lg: "calc(60vw / 3)"}}
        >
          <Box component="img" src="/imgs/therollup.svg" sx={{height: 40 }} />
        </Stack>
      </Stack>
  );
}

