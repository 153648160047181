import { Box, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import { SectionTitle } from '../../components/styled';
import { Anchors } from '../../core/config';
import { PartnersConfig } from '../../configs/partners';

export function Partners({config}:{config: PartnersConfig}) {
  const theme = useTheme();

  return (
    <Stack
      id={Anchors.partners}
      alignItems="center"
      sx={{
        background: theme.colors.functional.container.white,
        pt: { md: '81px', sm: '48px', xs: '33px' },
        pb: { md: '135px', sm: '60px', xs: '52px' },
      }}
    >
      <Typography width={{ xs: '116px', sm: '128px', md: '252px' }} component={SectionTitle} variant="h5">Partners</Typography>
      <Grid container mt={3} maxWidth={{xs: "80vw", lg: "60vw"}}>
        {config?.partners?.map((cur) => 
          <Grid item container justifyContent="center" alignItems="center" xs={6} sm={12/config.partnerCols} component={Link} 
            target="_blank" href={cur.website} rel="noopener noreferrer" 
            sx={{background: theme.colors.functional.container.white, border: "1px solid #EBE8E8", p:4}}>
            <Box component="img" src={cur.imgUrl} sx={{maxWidth: "6rem", height: 40  }} />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
