interface PartnerInfo {
  imgUrl:string;
  website:string
}

export interface PartnersConfig {
  specialHosts?: PartnerInfo[],
  coHosts: PartnerInfo[],
  partners: PartnerInfo[],
  partnerCols: number,
}

const brussels: PartnersConfig = {
  specialHosts: [{imgUrl:"/imgs/eigenlayer.svg",website:"https://www.eigenlayer.xyz"}],
  coHosts: [{imgUrl:"/imgs/babylon.svg",website:"https://babylonchain.io"}, {imgUrl:"/imgs/lombard.svg",website:"https://www.lombard.finance"}],
  partners: [
    {website: "https://www.optimism.io", imgUrl: "/imgs/op.svg"},
    {website: "https://www.gsr.io", imgUrl: "/imgs/gsr.svg"},
    {website: "https://www.lagrange.dev", imgUrl: "/imgs/lag.svg"},
    {website: "https://www.availproject.org", imgUrl: "/imgs/avail.svg"},
    {website: "https://www.hyperlane.xyz", imgUrl: "/imgs/hyper.svg"},
    {website: "https://www.risczero.com", imgUrl: "/imgs/risc.svg"},
    {website: "https://cyber.co", imgUrl: "/imgs/cyber.svg" },
    {website: "https://www.dodochain.com", imgUrl: "/imgs/dodo.svg"},
    {website: "https://www.puffer.fi", imgUrl: "/imgs/puffer.svg"},
  ],
  partnerCols: 3,
}
const singapore: PartnersConfig = {
  coHosts: [{imgUrl:"/imgs/eigenlayer.svg",website:"https://www.eigenlayer.xyz"}],
  partners: [
    {website: "https://www.optimism.io", imgUrl: "/imgs/op.svg"},
    {website: "https://polygon.technology", imgUrl: "/imgs/polygon.svg"},
    {website: "https://global.hashkey.com", imgUrl: "/imgs/hashkey.svg"},
    {website: "https://www.gsr.io", imgUrl: "/imgs/gsr.svg"},
    {website: "https://www.lagrange.dev", imgUrl: "/imgs/lag.svg"},
    {website: "https://brevis.network", imgUrl: "/imgs/brevis.svg"},
    {website: "https://www.availproject.org", imgUrl: "/imgs/avail.svg"},
    {website: "https://nuff.tech", imgUrl: "/imgs/nuffle.svg"},
    {website: "https://movementlabs.xyz", imgUrl: "/imgs/movement.svg"},
    {website: "https://frax.finance", imgUrl: "/imgs/frax.svg"},
    {website: "https://www.renzoprotocol.com", imgUrl: "/imgs/renzo.svg"},
    {website: "https://www.doublejump.tokyo", imgUrl: "/imgs/djt.svg"},
    {website: "https://gmnetwork.ai", imgUrl: "/imgs/gm-network.svg"},
    {website: "https://www.ata.network", imgUrl: "/imgs/automata.svg"},
    {website: "https://gopluslabs.io", imgUrl: "/imgs/goplus.svg"},
    {website: "https://nodeops.xyz", imgUrl: "/imgs/nodeops.svg"},
    {website: "https://www.kiln.fi", imgUrl: "/imgs/kiln.svg"},
    {website: "https://www.turboprotocol.xyz", imgUrl: "/imgs/turbo.svg"},
    {website: "https://thehemera.com", imgUrl: "/imgs/hemera.svg" },
  ],
  partnerCols: 4,
}

export const partners = {
  brussels,
  singapore
}