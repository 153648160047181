import { Box } from '@mui/material';

import { Speakers } from '../Home/Speakers';
import { speakers } from '../../configs/speakers';
import { partners } from '../../configs/partners';
import { Hosts } from '../Home/Hosts';
import { Partners } from '../Home/Partners';
import { MediaPartner } from '../Home/MediaPartner';
import Carousel from './Carousel';

export function Singapore() {
  return (
    <Box>
      <Carousel/>
      <Speakers data={speakers.singapore} />
      <Hosts config={partners.singapore}/>
      <Partners config={partners.singapore} />
      <MediaPartner />
    </Box>
  );
}
