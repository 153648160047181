import { GetComponentStyleFunc } from './types';

export const muiSvgIcon: GetComponentStyleFunc<'MuiSvgIcon'> = () => {
  return {
    styleOverrides: {
      root: {
        // fill: 'none',
      },
    },
  };
};
