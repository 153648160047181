import { Box } from '@mui/material';

import { Speakers } from './Speakers';
import { Hosts } from './Hosts';
import { Partners } from './Partners';
import { MediaPartner } from './MediaPartner';
import { Statistic } from './Statistic';
import { VideoBanner } from './VideoBanner';
import { Highlights } from './Highlights';
import { RestakedRollupDay } from './RestakedRollupDay';
import { speakers } from '../../configs/speakers';
import { partners } from '../../configs/partners';

export function Home() {
  return (
    <Box>
      <VideoBanner />
      <RestakedRollupDay />
      <Statistic />
      <Speakers data={speakers.brussels} />
      <Hosts config={partners?.brussels}/>
      <Partners config={partners?.brussels} />
      <MediaPartner />
      <Highlights />
    </Box>
  );
}
