import { Box, Stack, Typography, useTheme } from "@mui/material";
import EventVenue from "../../components/EventVenue";
import IconArrowCircleRight from "../../components/icons/IconArrowCircleRight";
import { Link } from "react-router-dom";


export function RestakedRollupDay() {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      sx={{
        position:"relative"
      }}
    >
      <Stack gap={2} sx={{
        background:theme.colors.functional.container.default,
        p: {xs:2,md:5},
        flex: 3,
        "& > * ": {
          zIndex: 10,
        }
      }}>
        <Typography component="span" sx={{background:"#5BBC90", width:"max-content", px:1.5, py:1, color:"#FFF"}} fontSize={{ md: '16.5px', sm: '8.5px', xs: '5px' }}>UPCOMING EVENT</Typography>
        <Typography sx={{display:"flex", alignItems:"flex-start", lineHeight:{ md: '105px', sm: '55px', xs: '23px' }}} fontSize={{ md: '105px', sm: '55px', xs: '23px' }}>Rollup Day<Box component="img" src="/imgs/rollup-day-star.svg" sx={{width:{xs:12,sm:28,md:34,lg:40}}}/></Typography>
        <EventVenue background="#000"/>
      </Stack>
      <Box sx={{background:theme.colors.functional.container.default, flex: 0.25, position:"relative"}}>
        <Box component="img" src="/imgs/sg-landmarks.png" sx={{position:"absolute", right: "15%", height: "60%", bottom: "5px", zIndex: 1}}/>
      </Box>
      <Stack sx={{background:theme.colors.functional.container.default,justifyContent:"center", alignItems:"center", flex: 1, p:1}}>
        <Typography variant="h5" sx={{display:"flex", flexWrap:"wrap", whiteSpace:"nowrap", gap:1, alignItems:"center", justifyContent:"center"}}>Learn more<Link to="/" style={{display:"flex"}}><IconArrowCircleRight sx={{width:{xs:"16px",sm:"36px",md:"64px"},color:"#000",height:{xs:"16px",sm:"36px",md:"64px"}}}/></Link></Typography>
      </Stack>
    </Stack>
  );
}
