import { useEffect, useRef, useState } from "react";
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";

import { Anchors } from "../../core/config";
import IconVolumeUp from "../../components/icons/IconVolumeUp";
import IconVolumeMute from "../../components/icons/IconVolumeMute";
import IconPlay from "../../components/icons/IconPlay";

export function VideoBanner() {
  const theme = useTheme();
  const [muted, setMuted] = useState(true);
  const [playError, setPlayError] = useState(false);
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setMuted((old) => !old);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("play", function () {
        setPlaying(true);
      });
      videoRef.current.play().catch(function (error) {
        console.log(error.message);
        setPlayError(true);
      });
    }
  }, []);

  return (
    <Stack
      sx={{
        position: "relative",
        background: theme.colors.functional.container.default,
      }}
    >
      <Box
        ref={videoRef}
        muted
        autoPlay
        playsInline
        component="video"
        sx={{
          width: "100%",
          height: "auto",
        }}
        loop
      >
        <source
          src="https://altlayer-image-store.alt.technology/restaked-rollup-day/highlight-for-web.mp4"
          type="video/mp4"
        />
      </Box>
      <IconPlay
        onClick={() => {
          if (videoRef.current) {
            videoRef.current.play().catch(function (error) {
              console.log(error.message);
            });
          }
        }}
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          display: playing ? 'none' : playError ? "block" : "none",
        }}
      />
      <Box
        sx={{
          cursor: "pointer",
          zIndex: 10,
          position: "absolute",
          right: { xs: 25, sm: 35, md: 50 },
          bottom: 40,
          [theme.breakpoints.up(960)]: {
            bottom: 80,
            right: 40,
          },
          [theme.breakpoints.up(1366)]: {
            bottom: 120,
            right: 50,
          },
          [theme.breakpoints.up(1840)]: {
            bottom: 200,
            right: 60,
          },
          [theme.breakpoints.up(2300)]: {
            bottom: 280,
            right: 80,
          },
        }}
        onClick={() => toggleMute()}
      >
        {!muted ? <IconVolumeUp /> : <IconVolumeMute />}
      </Box>
      <Stack
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="center"
        sx={{
          zIndex: 2,
          position: "absolute",
          bottom: "0px",
          width: "100%",
          height: { md: "256px", sm: "190px", xs: "60px" },
          pb: { md: "50px", sm: "30px", xs: "10px" },
          background:
            "linear-gradient(180deg, rgba(29, 39, 35, 0) 8.7%, #111715 150%)",
        }}
      >
        <Stack
          component={Link}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          href={`#${Anchors.highlights}`}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              mr: 2,
              borderRadius: "50%",
              background: theme.colors.functional.container.default,
              width: { md: "55px", sm: "30px", xs: "22px" },
              height: { md: "55px", sm: "30px", xs: "22px" },
              "& > img": {
                width: { md: "18px", sm: "15px", xs: "10px" },
                height: "auto",
              },
            }}
          >
            <Box component="img" src="/imgs/arrow-down.svg" />
          </Stack>
          <Typography
            textTransform="uppercase"
            color="white"
            fontSize={{ md: "17px", sm: "9.5px", xs: "7px" }}
          >
            Watch the full Highlights
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
