import { GetComponentStyleFunc } from './types';

export const muiTypography: GetComponentStyleFunc<'MuiTypography'> = theme => {
  return {
    defaultProps: {
      variant: 'body1',
      variantMapping: {
        h1: 'h1',
        h2: 'div',
        h3: 'div',
        h4: 'div',
        h5: 'div',
        h6: 'div',
        subtitle1: 'div',
        subtitle2: 'div',
        body1: 'div',
        body2: 'div',
      },
    },
    styleOverrides: {
      paragraph: {},
      caption: {},
      button: {},
      inherit: {},
      overline: {},
      h1: {
        fontSize: '24px',
        lineHeight: '24px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '40px',
          lineHeight: '42px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '80px',
          lineHeight: '80px',
        },
      },
      h2: {
        fontSize: '32px',
        lineHeight: 'normal',
        letterSpacing: '1.28px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '52px',
          letterSpacing: '2.08px'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '80px',
          letterSpacing: '3.2px'
        },
      },
      h3: {
        fontSize: '22px',
        lineHeight: 'normal',
        letterSpacing: '0.88px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '26px',
          letterSpacing: '1px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '40px',
          letterSpacing: '1.6px',
        },
      },
      h4: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '28px',
      },
      h5: {
        fontSize: '12px',
        lineHeight: 'normal',
        letterSpacing: "-0.636px",
        [theme.breakpoints.up('sm')]: {
          fontSize: '15px',
          letterSpacing: '0.6px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '30px',
          letterSpacing: '1.2px',
        },
      },
      h6: {
        fontSize: '10px',
        lineHeight: 'normal',
        fontWeight: 500,
        [theme.breakpoints.up('sm')]: {
          fontSize: '13px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '16px',
        },
      },
      subtitle1: {
        fontSize: '7px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '9px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '12px',
        },
      },
      subtitle2: {
        fontSize: '11px',
        lineHeight: '16px',
        letterSpacing: '0.66px',
        [theme.breakpoints.up('md')]: {
          fontSize: '13px',
          lineHeight: '18px',
          letterSpacing: '0.78px',
        },
      },
      body1: {
        lineHeight: '12px',
        fontSize: '10px',
        letterSpacing: '0.6px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '10px',
          letterSpacing: '0.6px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '20px',
          letterSpacing: '1.2px',
        },
      },
      body2: {
        lineHeight: 1.24,
        fontSize: '8.35px',
        letterSpacing: '0.5px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '13px',
          letterSpacing: '0.8px',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '26px',
          letterSpacing: '1.6px',
        },
      },
    },
  };
};
