'use client';

import { useState } from 'react';

import { Box, Drawer, List, ListItem, ListItemButton, Link as MuiLink, Stack, useTheme } from '@mui/material';

import { Link, useLocation } from 'react-router-dom';
import { lumaHref } from '../../core/config';
import IconLink from '../icons/IconLink';
import IconLogo from '../icons/IconLogoFull';
import IconMenu from '../icons/IconMenu';
import IconMenuClose from '../icons/IconMenuClose';

export default function MobileHeaderMenu() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <>
      <IconMenu
        onClick={() => setOpen(true)}
        sx={{ cursor: 'pointer', display: { sm: 'none', xs: undefined } }}
      />
      <Drawer
        anchor="right"
        keepMounted
        onClose={() => setOpen(false)}
        open={open}
        sx={{
          display: { xs: 'block', sm: 'hidden' },
          '& .MuiDrawer-paper': {
            width: "80%",
            maxWidth: "20rem",
            height: '100vh',
            background: theme.colors.functional.container.default,
          },
          // '& .MuiBackdrop-root': { bgcolor: 'transparent' },
        }}
      >
        <Stack flexDirection="row" px={2.5} py={1.75} justifyContent="space-between" sx={{ background: theme.colors.functional.container.default }}>
          <IconLogo />
          <IconMenuClose
            onClick={() => setOpen(false)}
            sx={{ width: '15px', height: 'auto', cursor: 'pointer' }}
          />
        </Stack>
        <List sx={{ 
          px: 0, py: 0, 
          "& .MuiListItemButton-root": {
            px: 2.5,
            py: 0,
            fontWeight: 700,
            fontSize: { md: '13px', xs: '11px', sm: '11px' },
            letterSpacing: { md: '0.78px', xs: '0.35px', sm: '0.5' },
            height: '59px',
            display: 'flex',
            alignItems: 'center',
            color: '#000',
            textWrap: 'nowrap',
            background: theme.colors.functional.container.default,
          } 
        }} >
          <ListItem sx={{ p: 0 }} >
            <ListItemButton
              component={Link}
              onClick={() => setOpen(false)}
              to={location.pathname?.includes("past-events")? "/":"/past-events"}
            >
              {location.pathname?.includes("past-events") ?"UPCOMING" : "PAST"} EVENTS
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ p: 0 }} >
            <ListItemButton
              component={MuiLink}
              onClick={() => setOpen(false)}
              href={lumaHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              REGISTER NOW <Box component={IconLink} sx={{position:"relative", top: -1, width: 12, height: 12, left: 5,}}/>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
