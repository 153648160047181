import { GetComponentStyleFunc } from './types';

export const muiLink: GetComponentStyleFunc<'MuiLink'> = theme => {
  return {
    styleOverrides: {
      root: {
        color: theme.colors.functional.text.primary,
        textDecoration: 'none',
      },
    },
  };
};
