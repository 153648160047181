import { CssBaseline } from '@mui/material';
import { Navigate, useRoutes } from 'react-router-dom';

import Layout from './components/Layout';
import { inputGlobalStyles } from './theme/globalStyle';
import { Home } from './pages/Home';
import { Singapore } from './pages/Singapore';

const DefaultRoute: React.FC = () => {
  return <Navigate to="/" />;
};

export const routes = [
  {
    element: <Singapore />,
    path: '/',
  },
  {
    element: <Home />,
    path: '/past-events',
  },
  {
    element: <DefaultRoute />,
    path: '*',
  }
];

const App: React.FC = () => {
  const content = useRoutes(routes);

  return (
    <>
      <CssBaseline />
      {inputGlobalStyles}
      <Layout>{content}</Layout>
    </>
  );
};

export default App;
