import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconLogo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ height: { xs: '15px', md: '22px' }, width: 'auto', ...sx }} viewBox="0 0 112 22" {...props}>
      <g clipPath="url(#clip0_1_6)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.169184 6.41492C-0.0563948 6.59642 -0.0563948 6.94109 0.169184 7.12259L1.3371 8.06492C1.50265 8.19875 1.73732 8.19692 1.90287 8.06309L8.56473 2.60709C8.67024 2.52092 8.73027 2.39075 8.73027 2.25509V0.454755C8.73027 0.0752547 8.29367 -0.137412 7.99896 0.100921L0.169184 6.41492ZM2.1812 11.9149C2.01748 12.0488 1.78098 12.0506 1.61544 11.9168L0.542117 11.0514C0.318358 10.8699 0.316538 10.5271 0.540298 10.3456L7.99714 4.24059C8.29185 4.00042 8.73209 4.21125 8.73209 4.59259V6.33609C8.73209 6.47175 8.67024 6.60192 8.56655 6.68809L2.18302 11.9149H2.1812ZM0.818634 14.1993C0.596693 14.3808 0.596693 14.7254 0.822272 14.9051L8.00078 20.6929C8.29549 20.9313 8.73209 20.7186 8.73209 20.3391V8.67359C8.73209 8.29225 8.29185 8.08142 7.99714 8.32159L0.818634 14.1993ZM9.63259 20.1888C9.63259 20.5921 10.1165 20.7956 10.4003 20.5096L20.1166 10.7178C20.293 10.5399 20.293 10.2539 20.1166 10.0761L10.4003 0.286088C10.1165 8.80122e-05 9.63259 0.203588 9.63259 0.606921V20.1888Z" fill="#1F2723"/>
      <path d="M105.458 17.6752V7.61016H107.521V8.23349C107.521 8.85683 107.516 9.30049 107.501 9.56449C107.803 8.89899 108.258 8.35816 108.864 7.94199C109.482 7.51299 110.259 7.29666 111.194 7.29666C111.471 7.29666 111.738 7.31683 112 7.35899V9.35549C111.503 9.31333 111.189 9.29316 111.05 9.29316C109.922 9.29316 109.049 9.57733 108.429 10.1457C107.825 10.714 107.521 11.5665 107.521 12.7032V17.6733H105.458V17.6752Z" fill="#1F2723"/>
      <path d="M98.1287 18.007C96.6006 18.007 95.3908 17.5358 94.4958 16.5935C93.6171 15.6512 93.1769 14.3202 93.1769 12.6005C93.1769 11.5738 93.3825 10.659 93.7954 9.85599C94.2211 9.03832 94.7996 8.40765 95.5291 7.96399C96.2713 7.50749 97.1027 7.27832 98.025 7.27832C99.5113 7.27832 100.666 7.76415 101.491 8.73399C102.316 9.69099 102.729 10.9945 102.729 12.6427V13.079H95.2599C95.2744 14.091 95.5491 14.894 96.0858 15.4917C96.6224 16.0875 97.3101 16.3863 98.1487 16.3863C98.7818 16.3863 99.3185 16.2342 99.7587 15.928C100.212 15.6237 100.486 15.1452 100.583 14.4925H102.648C102.537 15.5045 102.089 16.3442 101.305 17.0078C100.521 17.6733 99.4622 18.0052 98.1287 18.0052V18.007ZM100.583 11.6032C100.57 10.7708 100.335 10.1053 99.8824 9.60665C99.4276 9.10799 98.8091 8.85865 98.025 8.85865C97.2409 8.85865 96.6424 9.12265 96.1476 9.64882C95.6528 10.1622 95.3708 10.813 95.3017 11.6032H100.583Z" fill="#1F2723"/>
      <path d="M84.4684 22L86.2221 17.4258L82.2599 7.61017H84.5503L86.8825 14.0562C87.0062 14.3898 87.1517 14.7987 87.3155 15.2827C87.4392 14.8243 87.5702 14.4155 87.7084 14.0562L89.8332 7.61017H91.9799L86.6551 22H84.4684Z" fill="#1F2723"/>
      <path d="M75.478 18.007C74.5011 18.007 73.6752 17.7302 73.0021 17.1747C72.3418 16.6063 72.0107 15.851 72.0107 14.9087C72.0107 13.1212 73.2004 12.0523 75.5817 11.7058L77.541 11.4363C77.9267 11.3813 78.1941 11.2768 78.3451 11.1247C78.5106 10.9578 78.5943 10.7305 78.5943 10.439C78.5943 9.95315 78.4142 9.57182 78.0577 9.29499C77.7138 9.00349 77.2463 8.85865 76.6532 8.85865C75.9529 8.85865 75.4016 9.03282 75.0032 9.37932C74.6048 9.72582 74.392 10.197 74.3629 10.7928H72.2799C72.3218 10.142 72.5128 9.55165 72.8566 9.02549C73.215 8.48465 73.7098 8.06115 74.3429 7.75682C74.976 7.43782 75.7054 7.27832 76.5295 7.27832C77.8921 7.27832 78.909 7.63215 79.584 8.33799C80.2716 9.03099 80.6154 10.0155 80.6154 11.2915V17.6752H78.6762V17.1967C78.6762 17.017 78.6889 16.7182 78.718 16.302C78.4561 16.8282 78.0431 17.2443 77.4792 17.5487C76.9298 17.853 76.2621 18.007 75.478 18.007ZM75.891 16.4267C76.6751 16.4267 77.3209 16.1902 77.8303 15.719C78.3524 15.2332 78.6143 14.6025 78.6143 13.827V12.496C78.4906 12.6628 78.105 12.8077 77.4591 12.9323L76.2003 13.1615C75.5399 13.2862 75.0233 13.4878 74.6521 13.7647C74.2956 14.0287 74.1155 14.4027 74.1155 14.8867C74.1155 15.3707 74.281 15.7318 74.6103 16.0087C74.9559 16.2855 75.3816 16.4248 75.891 16.4248V16.4267Z" fill="#1F2723"/>
      <path d="M70.7518 17.6752H60.8263V3.1185H63.013V15.8033H70.7518V17.6752Z" fill="#1F2723"/>
      <path d="M56.1928 17.842C54.9558 17.842 53.9916 17.5432 53.304 16.9473C52.6163 16.3368 52.2725 15.3872 52.2725 14.0983V9.27482H49.4036V7.61199H52.2725V4.15982H54.3154V7.61199H57.5954V9.27482H54.3154V13.849C54.3154 14.6392 54.4937 15.2002 54.8521 15.5338C55.2232 15.8675 55.8217 16.0325 56.6476 16.0325C57.0187 16.0325 57.3698 16.005 57.6991 15.95V17.655C57.2316 17.7797 56.7295 17.842 56.1928 17.842Z" fill="#1F2723"/>
      <path d="M46.4129 3.1185V15.9903H48.4558V17.6752H41.9777V15.9903H44.3718V4.80333H42.3907V3.1185H46.4147H46.4129Z" fill="#1F2723"/>
      <path d="M40.7298 17.6752H38.3575L36.955 13.475H31.3846L29.982 17.6752H27.7117L32.8709 3.12033H35.5524L40.7316 17.6752H40.7298ZM32.0013 11.6233H36.3346L34.6428 6.54866L34.168 4.94816C34.017 5.53116 33.8587 6.06466 33.6932 6.54866L32.0013 11.6233Z" fill="#1F2723"/>
    </g>
    <defs>
      <clipPath id="clip0_1_6">
        <rect width="112" height="22" fill="white"/>
      </clipPath>
    </defs>
    </SvgIcon>
  );
}
