import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { lumaHref } from '../../core/config';
import IconArrowCircleRight from '../../components/icons/IconArrowCircleRight';
import EventVenue from '../../components/EventVenue';

const Carousel: React.FC = () => {
  const imgs = ["/imgs/carousel/1.jpg", "/imgs/carousel/2.jpg", "/imgs/carousel/3.jpg"];

  return (
    <Box component={Swiper}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
        sx={{
          width: "100vw",
          minHeight: "15rem",
          maxHeight:"90vh",
          background: (theme) => theme.colors.functional.container.default,
          "& .swiper-pagination": {
            bottom: {xs: 115, sm: 140, md: 210}, 
            width: { xs: "4rem", sm:"6rem",md: "8rem"}
          },
          "& .swiper-pagination-bullet": {
            background:"#FFF",
            width: {
              xs: 8,
              lg: 15
            },
            height: {
              xs: 8,
              lg: 15
            }

          },
          mb:-5
        }}
      >
      {imgs.map((url, index) => (
        <SwiperSlide key={index} style={{overflow:"hidden"}}>
          <Box component="img" src={url} sx={{width: {xs:"150%",sm:"100%"}, height: "100%"}}  />
        </SwiperSlide>
      ))}
      <Grid container sx={{position: "absolute", pt:0, bottom: {xs:30, lg:60}, left: {xs:10, sm: 30}, zIndex: 100, width: {xs:"calc(100% - 20px)",sm:"calc(100% - 60px)"}, color: "#FFF"}} spacing={1}>
        <Grid item xs={12} lg={8.5} flexDirection="column" container gap={{xs:1,md:2}} pt={0}>
          <Typography variant='h1' whiteSpace="nowrap">AltLayer Rollup Day</Typography>
          <EventVenue/>
        </Grid>
        <Grid item xs={12} lg={3.5} flexDirection="column" container>
          <Stack direction={{xs: "row", lg: "column"}} sx={{height: "100%", pt:{xs:0,lg:2.5}}} alignItems="flex-end" justifyContent={{xs:"flex-start", lg:"space-around"}} spacing={2}>
            <Typography component={Link} href={lumaHref} rel="noopener noreferrer" target="_blank" sx={{color: "#FFF", display:"flex", alignItems:"center", gap:{xs:1,md:2}, whiteSpace: "nowrap"}}>Register Now<IconArrowCircleRight sx={{width:{xs:20, md:30, lg: 50}, height:{xs:20, md:30, lg: 50}, color: "#5BBC90"}}/></Typography>
            <Typography component={Link} href="https://altlayer.io" rel="noopener noreferrer" target="_blank" sx={{color: "#FFF", whiteSpace:"nowrap", display:"flex", alignItems:"center", gap:{xs:1,md:2}}}>Learn more about AltLayer<IconArrowCircleRight sx={{width:{xs:20, md:30, lg: 50}, height:{xs:20, md:30, lg: 50}, color: "#5BBC90"}}/></Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Carousel;
