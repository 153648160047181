import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { px } from '../../theme/box';
import IconLogo from '../icons/IconLogo';
import { EcoLinks } from './EcoLinks';

const Footer: FC<BoxProps> = ({ ...rest }) => {
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative', zIndex: 10, background: 'white', border: `1px solid ${theme.colors.functional.subject.border}` }}>
      <EcoLinks sx={{ display: { xs: 'none', sm: 'flex' } }} />
      <Stack
        alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
        justifyContent="space-between"
        flexDirection={{ xs: 'column', sm: 'row' }}
        px={px}
        pb={{ xs: 3.5, md: 5 }}
        pt={{ xs: 3.5, sm: 12.5, md: 22.5 }}
      >
        <IconLogo sx={{ width: 'auto', height: { md: '48px', sm: '40px', xs: '28px' } }} />
        <Stack flexDirection="column" mt={{ xs: 10, sm: 0 }}>
          <Typography variant="subtitle2" color={theme.colors.functional.text.lint}>Contact us</Typography>
          <Typography variant="subtitle2" mt={0.5}>support@altresear.ch</Typography>
        </Stack>
      </Stack>
      <EcoLinks sx={{ display: { xs: 'flex', sm: 'none' } }} />
    </Box>
  );
};

export default Footer;
