import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconEnter({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 'auto', height: { xs: '41px', sm: '59px', md: '84px' }, ...sx }} viewBox="0 0 83 84" {...props}>
        <ellipse cx="41.5" cy="42" rx="41.5" ry="42" fill="#202723"/>
        <path d="M30 41.5H53M53 41.5L41.5 30M53 41.5L41.5 53" stroke="url(#paint0_linear_44_1783)" strokeLinecap="square"/>
        <defs>
          <linearGradient id="paint0_linear_44_1783" x1="30.422" y1="30" x2="52.578" y2="53" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="#F7F7F7"/>
          </linearGradient>
        </defs>
    </SvgIcon>
  );
}
