import { Box, Link, Stack, Typography } from "@mui/material";
import { CSSProperties } from "react";

export default function EventVenue({background = "#5BBC90"} :{background?: CSSProperties["background"]}) {
  return (
    <Stack direction="row" gap={{xs: 1, md: 2}} flexWrap="wrap">
      <Typography letterSpacing={{xs:'-0.2px',sm:0.6}} fontSize={{xs: 9, md: 16}} sx={{background, px: {sm: 2, xs:0.5}, py: 0.5, whiteSpace:"nowrap", color:"#FFF", display:"flex",alignItems:"center"}}>20 Sep 2024 | 10am-5.30pm</Typography>
      <Typography letterSpacing={{xs:'-0.2px',sm:0.6}} fontSize={{xs: 9, md: 16}} component={Link} href="https://maps.app.goo.gl/pR8arkP9XNxYhgm39" target="_blank" rel="noopener noreferrer" sx={{px:{sm: 2, xs:0.5}, py:0.5, display:"flex",alignItems:"center",gap:{xs:0.5,sm:1},background, whiteSpace:"nowrap", color: "#FFF"}}>
        <Box component="img" src="/imgs/location.svg" sx={{ width:{ xs: 10, sm: 15 }, height:{ xs: 10, sm: 15 }}}/>
        InterContinental Singapore
      </Typography>
    </Stack>
  )
}