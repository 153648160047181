import { createTheme, responsiveFontSizes } from '@mui/material';

import { fontCss } from './font';
import { Colors } from './types';
import { muiSvgIcon } from './MuiSvgIcon';
import { muiTypography } from './MuiTypography';
import { muiLink } from './MuiLink';

const colors: Colors = {
  schema: {
    primary: '#6667AB',
    secondary: '#9092FE',
    success: '#39BA72',
    info: '#9092FE',
    error: '#FF6F7D',
    failure: '#F83B4C',
  },
  functional: {
    text: {
      primary: '#000',
      link: '#5C8CE1',
      lint: 'rgba(32, 39, 35, 0.4)',
      dark: '#202723',
    },
    subject: {
      border: 'rgba(32, 39, 35, 0.03)', 
    },
    container: {
      black: '#202723',
      default: 'linear-gradient(106deg, #EAF4DC 0.33%, #D5E9BB 99.67%)',
      white: 'linear-gradient(136deg, #FFF 0.9%, #F7F7F7 99.1%)',
    },
  },
};

const baseTheme = createTheme({
  typography: {
    fontFamily: 'ABC Diatype Semi-Mono',
  },
  colors,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    text: { primary: colors.functional.text.primary, secondary: colors.functional.text.lint },
    primary: { main: colors.schema.primary },
    secondary: { main: colors.schema.secondary },
    error: { main: colors.schema.error },
    warning: { main: colors.schema.error },
    info: { main: colors.schema.info },
    success: { main: colors.schema.success },
    action: {
      disabled: colors.functional.text.primary,
    },
  }
});

export const theme = responsiveFontSizes({
  ...baseTheme,
  components: {
    MuiTypography: muiTypography(baseTheme),
    MuiSvgIcon: muiSvgIcon(baseTheme),
    MuiLink: muiLink(baseTheme),
    MuiCssBaseline: {
      styleOverrides:
        `
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Works for Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        body {
          min-height: 100vh;
        }
      ` + fontCss(),
    },
  },
});
