import {
  Box,
  Grid,
  Link,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from "@mui/material";

import IconEnter from "../../components/icons/IconEnter";
import { px } from "../../theme/box";
import { useState } from "react";
import { Anchors } from '../../core/config';

interface Highlight {
  title: string;
  author: string;
  source: string;
}

const highlights: Highlight[] = [
  {
    title: "Opening Note",
    author: "Amrit Kumar",
    source:
      "https://www.youtube.com/embed/u47FKI2udKo?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=18",
  },
  {
    title: "Keynote",
    author: "Balaji Srinivasan",
    source:
      "https://www.youtube.com/embed/v0HqW0NQmM0?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=17",
  },
  {
    title: "Keynote",
    author: "Ed Felten",
    source:
      "https://www.youtube.com/embed/bGZX-0S8uHg?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=16",
  },
  {
    title: "Keynote",
    author: "Robert Drost",
    source:
      "https://www.youtube.com/embed/WB9fp64p-6s?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=15",
  },
  {
    title: "Restaking panel",
    author: "Rich Rosenblum, Robert Drost, YQ, Mike Silagadze, Luke Pearson",
    source:
      "https://www.youtube.com/embed/VW-3OBivjkQ?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=14",
  },
  {
    title: "Restaked Rollup panel",
    author: "Nader Dabit, Ryan Li, Kilian Boshoff, Graeme Taylor",
    source:
      "https://www.youtube.com/embed/rwqSkI3TlRU?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=13",
  },
  {
    title: "Defi & LRT panel",
    author: "Ray Xiao, Alex Odagiu, Amrit Kumar, Amir Forouzani, Kratik Lodha",
    source:
      "https://www.youtube.com/embed/wKZLdVd_nzk?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=12",
  },
  {
    title: "ZK Coprocessor panel",
    author: "Etienne Vant Kruys, Ismael H.R, Mo Dong, Steven Li ",
    source:
      "https://www.youtube.com/embed/ja6CFGeA1wQ?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=11",
  },
  {
    title: "Modularity panel",
    author:
      "Andy, Ben Fisch, Prabal Banerjee, Tess Rinearson, Yorke Rhodes, Chris Cordle",
    source:
      "https://www.youtube.com/embed/mXZI6D9rZrw?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=10",
  },
  {
    title: "Keynote",
    author: "Robert Miller",
    source:
      "https://www.youtube.com/embed/W5ucjdbvzlE?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=9",
  },
  {
    title: "Keynote",
    author: "YQ",
    source:
      "https://www.youtube.com/embed/uGLu4GFKBuU?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=8",
  },
  {
    title: "Keynote",
    author: "Vitalik Buterin",
    source:
      "https://www.youtube.com/embed/0kPHFiyiQuI?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=7",
  },
  {
    title: "Keynote",
    author: "Tarun Chitra",
    source:
      "https://www.youtube.com/embed/hN3v3cceFiA?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=6",
  },
  {
    title: "Keynote",
    author: "David Tse",
    source:
      "https://www.youtube.com/embed/Vs8bfz27Xiw?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=5",
  },
  {
    title: "Fireside Chat",
    author: "Justin Drake, David Tse",
    source:
      "https://www.youtube.com/embed/8E5Dn21hu0g?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=4",
  },
  {
    title: "Keynote",
    author: "Kevin McCordic",
    source:
      "https://www.youtube.com/embed/gJTm9KLyPqw?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=3",
  },
  {
    title: "Keynote",
    author: "Sandeep Nailwal",
    source:
      "https://www.youtube.com/embed/kSEx6C2_25E?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=2",
  },
  {
    title: "ZKEVM, altVM & Parallel EVM panel",
    author: "David Hoffman, Sandeep Nailwal, Kevin Galler, Smokey, Lei Yang",
    source:
      "https://www.youtube.com/embed/dNHSkmB23jY?list=PLUFg68W_DON5m0oH9NtqGOP6SfIc4N5Gx&index=1",
  },
];

export function Highlights() {
  const theme = useTheme();
  const [highlightIndex, setHighlightIndex] = useState(0);

  return (
    <Box id={Anchors.highlights} sx={{ background: theme.colors.functional.container.default }} mb={{ md: '114px', xs: '77px' }}>
      <Stack
        py={{ md: 3, xs: 1.5 }}
        px={px}
        flexDirection={{ md: "row", sm: "row", xs: "column" }}
        alignItems={{ md: "center", sm: "center", xs: "flex-start" }}
        justifyContent={{ md: "space-between", sm: "space-between", xs: "flex-start" }}
      >
        <Typography variant="h2">Highlights</Typography>
        <Stack
          sx={{
            background: "white",
            borderRadius: { md: "53px", sm: "26px", xs: "16.5px" },
            height: { md: "106px", sm: "52px", xs: "33px" },
            px: { md: "40px", sm: "20px", xs: "14px" },
            my: { md: "0px", xs: 1.25 },
            display: 'flex',
          }}
          flexDirection="row"
          alignItems="center"
          component={Link}
          href="https://www.youtube.com/@altlayer7257"
        >
          AltLayer Youtube Channel
          <IconEnter
            sx={{
              ml: { md: 3.5, sm: 1.75, xs: 1 },
              height: { md: "62px", sm: "30px", xs: "20px" },
            }}
          />
        </Stack>
      </Stack>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Stack>
            <Box
              component="iframe"
              width="100%"
              height={{ md: "584px", sm: "290px", xs: "220px" }}
              src={highlights[highlightIndex].source}
              title={highlights[highlightIndex].title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </Stack>
        </Grid>
        <Grid
          height={{ md: "600px", sm: "300px", xs: "220px" }}
          item
          xs={12}
          md={6}
          sx={{ overflowY: "auto" }}
        >
          {highlights.map((hightlight, index) => (
            <HighLight
              {...hightlight}
              onClick={() => setHighlightIndex(index)}
              key={index}
              playing={highlightIndex === index}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

function HighLight({
  title,
  author,
  playing,
  onClick
}: Highlight & { playing: boolean, onClick: () => void }) {
  return (
    <Stack
      py={{ xs: 1.25, md: 2.5 }}
      bgcolor={playing ? "#EAF4DC" : "transparent"}
      flexDirection="row"
      alignItems="center"
      px={{ md: 4, xs: 2 }}
      sx={{ cursor: 'pointer', ":hover": { bgcolor: '#EAF4DC' } }}
      onClick={onClick}
    >
      <IconPlaying playing={playing} mr={{ md: 4, xs: 2 }} />
      <Box>
        <Typography mb={{ xs: 0.5, md: 1 }} variant="body2">{title}</Typography>
        <Typography variant="body1">{author}</Typography>
      </Box>
    </Stack>
  );
}

function IconPlaying({ playing, ...props }: { playing: boolean } & StackProps) {
  return (
    <Stack
      borderRadius="50%"
      flexShrink={0}
      bgcolor="black"
      height={{ md: "47px", xs: "24px" }}
      width={{ md: "47px", xs: "24px" }}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {playing && (
        <Box
          bgcolor="white"
          height={{ xs: "7px", md: "14px" }}
          width={{ xs: "7px", md: "14px" }}
        />
      )}
    </Stack>
  );
}
