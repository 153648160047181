import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { SectionTitle } from '../../components/styled';
import { Anchors } from '../../core/config';
import { PartnersConfig } from '../../configs/partners';

function SpecialHost({config}:{config: PartnersConfig}) {
  const theme = useTheme();

  return !config?.specialHosts ? null : (
    <Stack
      id={Anchors.sponsors}
      alignItems="center"
      sx={{
        background: theme.colors.functional.container.white,
        pt: { md: '81px', sm: '48px', xs: '33px' },
        pb: { md: '135px', sm: '60px', xs: '52px' },
      }}
    >
      <Typography width={{ xs: '116px', sm: '128px', md: '252px' }} mb={5} component={SectionTitle} variant="h5">Special Host{config?.specialHosts?.length > 1 ? "s": ""}</Typography>
      {config?.specialHosts?.map(cur => 
      <Link key={cur?.website} target="_blank" href={cur.website} rel="noopener noreferrer" sx={{background: theme.colors.functional.container.white, border: "1px solid #EBE8E8",py:1.7,px: {xs: 4, lg: 6, xl: 10}}}>
        <Box component="img" src={cur.imgUrl} sx={{height:"44px"}} />
      </Link>)}
    </Stack>
  );
}

function CoHosts({config}:{config: PartnersConfig}) {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      sx={{
        background: theme.colors.functional.container.white,
        pt: { md: '81px', sm: '48px', xs: '33px' },
        pb: { md: '135px', sm: '60px', xs: '52px' },
      }}
    >
      <Typography width={{ xs: '116px', sm: '128px', md: '252px' }} mb={5} component={SectionTitle} variant="h5">Co-host{config?.coHosts?.length > 1 ? "s": ""}</Typography>
      <Stack flexDirection="row" justifyContent="space-between">
        {config?.coHosts?.map((cur) => 
          <Link  key={cur?.website} target="_blank" href={cur.website} rel="noopener noreferrer" sx={{background: theme.colors.functional.container.white, border: "1px solid #EBE8E8", py:4, px: {xs: 2, lg: 6, xl: 10}}}>
            <Box component="img" src={cur.imgUrl} height={{ sm: '31px', xs: '23px' }} />
          </Link>  
        )}
      </Stack>
    </Stack>
  );
}

export function Hosts({config}:{config: PartnersConfig}) {
  return (
    <Box>
      <SpecialHost config={config} />
      <CoHosts config={config}/>
    </Box>
  );
}
