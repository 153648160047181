import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconPlay({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 'auto', height: { xs: '33px', sm: '45px', md: '60px' }, ...sx }} viewBox="0 0 33 33" {...props}>
      <g id="Group 40587">
      <g id="Group 40571">
      <circle id="Ellipse 5" opacity="0.5" cx="16.5" cy="16.5" r="16.5" fill="#1D2723"/>
      </g>
      <path id="Polygon 1" d="M25 16L11.5 23.7942L11.5 8.20577L25 16Z" fill="#D9D9D9"/>
      </g>
    </SvgIcon>
  );
}
