export interface SpeakerData { speaker: string; title: string; img: string }

const brussels: SpeakerData[] = [
  { speaker: 'Vitalik Buterin', title: 'Ethereum Foundation', img: 'Vitalik.png' },
  { speaker: 'Balaji Srinivasan', title: 'ex-CTO of CoinbaseGP at a16z', img: 'Balaji.png' },
  { speaker: 'Justin Drake', title: 'Ethereum Foundation', img: 'Justin Brake.png' },
  { speaker: 'Sandeep Nailwal', title: 'Cofounder of Polygon', img: 'Sandeep.png' },
  { speaker: 'Ed Felten', title: 'Cofounder of Offchain Labs', img: 'Ed F.png' },
  { speaker: 'Robert Drost', title: 'Director of Eigen Foundation', img: 'Robert B.png' },
  { speaker: 'David Tse', title: 'Cofounder of Babylon', img: 'David Tse.png' },
  { speaker: 'Luke Pearson', title: 'Lombard & Polychain', img: 'Luke.png' },
  { speaker: 'Rich Rosenblum', title: 'Co-CEO of GSR', img: 'Rich.png' },
  { speaker: 'YQ', title: 'Founder of AltLayer', img: 'YQ.png' },
  { speaker: 'Mike Silagadze', title: 'CEO of Ether.fi', img: 'Mike.png' },
  { speaker: 'David Hoffman', title: 'Cofounder of Bankless', img: 'David.png' },
  { speaker: 'Etienne Vant Kruys', title: 'Managing Partner at TRGC', img: 'Etienne.png' },
  { speaker: 'Robert Miller', title: 'Steward of Flashbots', img: 'Robert M.png' },
  { speaker: 'Nader Dabit', title: 'Director of Developer Relations at Eigen Labs', img: 'Nader.png' },
  { speaker: 'Smokey', title: 'Founder of Berachain', img: 'Smokey.png' },
  { speaker: 'James Hunsaker', title: 'Cofounder & CTO of Monad', img: 'James.png' },
  { speaker: 'Ben Fisch', title: 'CEO & Co-Founder of Espresso', img: 'Ben.png' },
  { speaker: 'Amir Forouzani', title: 'Core Contributor of Puffer', img: 'Amir.png' },
  { speaker: 'Prabal Banerjee', title: 'Cofounder of Avail', img: 'Prabal.png' },
  { speaker: 'Mo Dong', title: 'Cofounder of Brevis', img: 'Mo Dong.png' },
  { speaker: 'Ismael', title: 'Cofounder of Lagrange', img: 'Ismeal.png' },
  { speaker: 'Lei Yang', title: 'CTO & Cofounder of MegaETH', img: 'Lei Yang.png' },
  { speaker: 'Tarun Chitra', title: 'Cofounder of Gauntlet', img: 'Tarun.png' },
  { speaker: 'Alex Odagiu', title: 'Senior Investment Director of Binance Labs', img: 'Alex.png' },
  { speaker: 'Ryan Li', title: 'Cofounder of Cyber', img: 'Ryan Li.png' },
  { speaker: 'Steven Li', title: ' Head of Strategy of RISC ZERO', img: 'Steven.png' },
  { speaker: 'Ray Xiao', title: 'Senior Director at IOSG Ventures', img: 'Ray Xiao.png' },
  { speaker: 'Andy', title: 'Cofounder of The Rollup', img: 'Andy.png' },
  { speaker: 'Graeme Taylor', title: 'Cofounder of Sonic Digital Labs', img: 'Graeme.png' },
  { speaker: 'Kilian Boshoff', title: 'Head of Partnership of Swell', img: 'Kilian.png' },
  { speaker: 'Kratik Lodha', title: ' Founding Contributor of Renzo', img: 'Kratik.png' },
  { speaker: 'Yorke Rhodes', title: 'Founding Engineer of Hyperlane', img: 'Yorke.png' },
  { speaker: 'Tess Rinearson', title: 'Optimism', img: 'Tess.png' },
  { speaker: 'Chris Cordle', title: 'Offchain Labs', img: 'Chris.png' },
  { speaker: 'Amrit Kumar', title: 'COO of AltLayer', img: 'Amrit.png' },
];

const singapore :SpeakerData[] = [
  { speaker: 'Vitalik Buterin', title: 'Ethereum Foundation', img: 'Vitalik.png' },
  { speaker: 'Sandeep Nailwal', title: 'Cofounder of Polygon', img: 'Sandeep.png' },
  { speaker: 'Eli Ben Sasson', title: 'Co-founder and CEO of Starkware', img: 'Eli Ben Sasson.png' },
  { speaker: 'Sreeram Kannan', title: 'Founder of EigenLayer', img: 'Sreeram Kannan.png' },
  { speaker: 'Fisher Yu', title: 'Co-founder of Babylon', img: 'Fisher Yu.png' },
  { speaker: 'Keone Hon', title: 'Co-founder & CEO of Monad', img: 'Keone Hon.png' },
  { speaker: 'Stani Kulechov', title: 'Founder of Aave Labs', img: 'Stani Kulechov.png' },
  { speaker: 'Sota Watanabe', title: 'Co-founder of Soneium and Astar', img: 'Sota Watanabe.png' },
  { speaker: 'Dovey Wan', title: 'Founder of Primitive Ventures', img: 'Dovey Wan.png' },
  { speaker: 'YQ', title: 'Founder of AltLayer', img: 'YQ.png' },
  { speaker: "Loi Luu", title: "Founder of Caliber & Kyber", img: "Loi Luu.png"},
  { speaker: 'Sandy Peng', title: 'Co-founder of Scroll', img: 'Sandy Peng.png' },
  { speaker: 'Mert Mumtaz', title: 'Co-founder & CEO of Helius', img: 'Mert Mumtaz.png' },
  { speaker: 'Eric Wall', title: 'Co-founder of Taproot Wizards', img: 'Eric Wall.png' },
  { speaker: 'Emad Mostaque', title: 'Founder of Schelling AI', img: 'Emad Mostaque.png' },
  { speaker: 'Mike Silagadze', title: 'CEO of Ether.fi', img: 'Mike.png' },
  { speaker: 'TN', title: 'Founder of Pendle', img: 'TN.png' },
  { speaker: 'Shuyao', title: 'Co-founder of MegaETH', img: 'Shuyao.png' },
  { speaker: 'Sam Kazamian', title: 'Founder of Frax Finance', img: 'Sam Kazamian.png' },
  { speaker: 'Prabal Banerjee', title: 'Cofounder of Avail', img: 'Prabal.png' },
  { speaker: 'Smokey', title: 'Founder of Berachain', img: 'Smokey.png' },
  { speaker: 'Altan Tutar', title: 'Co-founder & CEO of Nuffle', img: 'Altan Tutar.png' },
  { speaker: 'Ismael', title: 'Cofounder of Lagrange', img: 'Ismeal.png' },
  { speaker: 'Michael Tung', title: 'Co-founder of Brevis', img: 'Michael Tung.png' },
  { speaker: 'Michael Tong', title: 'CEO of Xterio', img: 'Michael Tong.png' },
  { speaker: 'Ryan Li', title: 'Cofounder of Cyber', img: 'Ryan Li.png' },
  { speaker: 'Tarun Chitra', title: 'CEO of Gauntlet', img: 'Tarun Chitra.png' },
  { speaker: 'Kay', title: 'CEO of HashKey Eco Labs', img: 'Kay.png' },
  { speaker: 'Deli Gong', title: 'Co-founder of Automata', img: 'Deli Gong.png' },
  { speaker: 'Lucas Kozinski', title: 'Founder of Renzo', img: 'Lucas Kozinski.png' },
  { speaker: 'Rushi Manche', title: 'Co-founder of Movement Labs', img: 'Rushi Manche.png' },
  { speaker: 'Eskil Xu', title: 'Co-founder of Goplus', img: 'Eskil Xu.png' },
  { speaker: 'Calvin Chu', title: 'Co-founder of Impossible Finance', img: 'Calvin Chu.png' },
  { speaker: 'Chess', title: 'Founder of GM Network', img: 'Chess.png' },
  { speaker: 'Jason Li', title: 'Co-founder of Solayer', img: 'Jason Li.png' },
  { speaker: 'Arthur Meng', title: 'Founder & CEO of Hemera Protocol', img: 'Arthur Meng.png' },
  { speaker: 'Laszlo Szabo', title: 'Co-founder & CEO of Kiln', img: 'Laszlo Szabo.png' },
  { speaker: 'Naman Kabra', title: 'Founder & CEO of NodeOps', img: 'Naman Kabra.png' },
  { speaker: 'Aaron Greenblatt', title: 'PhD, Founder & CEO of Turbo Protocol', img: 'Aaron Greenblatt.png' },
  { speaker: 'Josh Wadinski', title: 'Strategy & Partnership of OP Labs', img: 'Josh Wadinski.png' },
  { speaker: 'Nader Dabit', title: 'Director of Developer Relations of Eigen Labs', img: 'Nader Dabit.png' },
  { speaker: 'Su', title: 'APAC lead of Eigen foundation', img: 'Su.png' },
  { speaker: 'Rui', title: 'Investment Manager of HashKey Capital', img: 'Rui.png' },
  { speaker: 'Naoki Oyama', title: 'Business Development Lead of doublejump.tokyo', img: 'Naoki Oyama.png' },
  { speaker: 'Andy', title: 'Co-founder of The Rollup', img: 'Andy.png' },
  { speaker: 'Francesco Andreoli', title: 'DevRel Lead of MetaMask', img: 'Francesco Andreoli.png' },
]

export const speakers = {
  brussels,
  singapore,
}