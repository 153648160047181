import { Grid, GridOwnProps, Link, Typography, styled, useTheme } from '@mui/material';
import IconLink from '../icons/IconLink';

const ecoLinks = [
  {
    category: 'Product',
    links: [
      { name: 'Restaked Rollups', href: 'https://www.altlayer.io/restaked-rollups' },
      { name: 'RAAS', href: 'https://www.altlayer.io/raas' },
      { name: 'Explore Ecosystem', href: 'https://www.altlayer.io/#section-ecosystem' },
    ],
  },
  {
    category: 'Resources',
    links: [
      { name: 'Dev Docs', href: 'https://docs.altlayer.io/altlayer-documentation' },
      { name: 'Blog', href: 'https://blog.altlayer.io/' },
      { name: 'news', href: 'https://www.altlayer.io/updates' },
    ],
  },
  {
    category: 'Community',
    links: [
      { name: 'Careers', href: 'https://careers.altlayer.io/b81ba67574d344d5bc85944f64840a2e' },
      { name: 'Oh Ottie! Collection', href: 'https://ottie.altlayer.io/' },
      { name: 'twitter / x', href: 'https://x.com/alt_layer' },
    ],
  },
];

const Item = styled(Link)(({ theme } ) => ({
  display: 'flex',
  background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
  borderRight: '1px solid var(--dark-3, rgba(32, 39, 35, 0.03))',
  borderBottom: '1px solid var(--dark-3, rgba(32, 39, 35, 0.03))',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  height: '56px',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    height: '56px',
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    height: '66px',
  },
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  '&:hover': {
    svg: {
      transition: 'transform 0.2s ease',
      transform: 'translate(0.25rem, -0.25rem)',
    }
  },
}));

export function EcoLinks(props: GridOwnProps) {
  const theme = useTheme();

  return (
    <Grid container {...props}>
      {ecoLinks.map(({category, links}) => (
        <Grid key={category} item xs={12} sm={4}>
          <Item color={theme.colors.functional.text.lint}>
            <Typography variant="subtitle2" color={theme.colors.functional.text.lint} fontWeight={700} textTransform="uppercase">{category}</Typography>
          </Item>
          {links.map(({ name, href }) => (
            <Item key={name} href={href}>
              <Typography variant="subtitle2" color={theme.colors.functional.text.dark} fontWeight={700} textTransform="uppercase">{name}</Typography>
              <IconLink />
            </Item>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

