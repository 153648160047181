import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconMenu({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '19px', height: 'auto', ...sx }} viewBox="0 0 19 13" {...props}>
      <rect y="0.288452" width="19" height="2.19231" fill="#202723"/>
      <rect y="10.5192" width="19" height="2.19231" fill="#202723"/>
    </SvgIcon>
  );
}
