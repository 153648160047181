import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import IconArrowCircleRight from '../../components/icons/IconArrowCircleRight';
import { SpeakerData } from '../../configs/speakers';
import { Anchors, lumaHref } from '../../core/config';
import { px } from '../../theme/box';
import { useLocation } from 'react-router-dom';

export function Speakers({data}:{data: SpeakerData[]}) {
  const theme = useTheme();
  const location = useLocation();

  return (
    <Box id={Anchors.speakers}>
      <Box sx={{ height: { md: '282px', sm: '194px', xs: '159px' }, background: theme.colors.functional.container.default }}><Typography variant="h2" sx={{ display: 'flex', alignItems: 'flex-end', height: '100%', pl: px, pb: { xs: 3.25, md: 4 } }} >Speakers</Typography></Box>
      <Grid container sx={{
      }}>
        {
          data?.map((speaker, index) => (
            <Speaker key={index} speaker={speaker.speaker} title={speaker.title} img={speaker.img} />
          ))
        }
        {!location.pathname?.includes("past-events") && <Link href={lumaHref} target="_blank" sx={{minHeight:{xs:"10rem",md:"15rem", lg: "17rem"}, background:theme.colors.functional.container.default, flex: 1, display: "flex", flexDirection:"column", p:3, justifyContent: "space-between"}}>
          <Typography variant='h3' sx={{fontSize:{xs:20, md: 40, xl: 48}}}>Register Now</Typography>
          <Typography sx={{textAlign: "right"}}><IconArrowCircleRight sx={{color: "black", width: {xs: 30, sm:40, lg: 50}, height: {xs: 30, sm:40, lg: 50}}}/></Typography>
        </Link>}
      </Grid>
    </Box>
  );
}

function Speaker({ speaker, title, img }: SpeakerData) {
  const theme = useTheme();

  return (
    <Grid item xs={6} sm={3} lg={2} sx={{ py: { xs: '20px', sm: '25px', md: '32px' }, background: theme.colors.functional.container.default, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box component="img" src={`/imgs/speakers/${img}`} borderRadius="50%" width={{ xs: '74px', sm: '89px', md: '116px' }} height={{ xs: '74px', sm: '89px', md: '116px' }} />
      <Typography textAlign="center" mt={{ xs: '10px', sm: '12px', md: '14px' }} variant="h6">{speaker}</Typography>
      <Typography maxWidth="165px" textAlign="center" mt={{ xs: 1, sm: 1, md: 1.25 }} variant="subtitle1">{title}</Typography>
    </Grid>
  );
}