import { Stack, styled } from '@mui/material';

export const SectionTitle = styled(Stack)(({ theme }) => ({
  fontSize: '24px',
  textTransform:"uppercase",
  textAlign: 'center',
  height: '26px',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: '51px',
  },
}));
