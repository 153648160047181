import { SvgIcon, SvgIconProps } from "@mui/material";

export default function IconLogoFull({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="159"
      height="45"
      sx={{ width: { xs: '86px', md: '185px' }, height: 'auto', ...sx }}
      viewBox="0 0 159 45"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_236_5662)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.870765 3.89215C0.734078 4.00212 0.734078 4.21202 0.870765 4.32199L1.58042 4.89324C1.6809 4.9743 1.82383 4.97368 1.92369 4.89198L5.97064 1.58141C6.0343 1.52925 6.07175 1.4507 6.07175 1.36774V0.27616C6.07175 0.0455259 5.80649 -0.0826738 5.62736 0.0612367L0.870765 3.89215ZM2.09284 7.23037C1.99297 7.31206 1.85005 7.31269 1.74956 7.23163L1.09795 6.70626C0.96189 6.59628 0.961266 6.38827 1.09608 6.27767L5.62549 2.57244C5.80399 2.42602 6.07113 2.55422 6.07113 2.78611V3.84439C6.07113 3.92734 6.0343 4.00589 5.97002 4.05805L2.09284 7.22974V7.23037ZM1.26585 8.61668C1.13041 8.72729 1.13166 8.93593 1.26772 9.04527L5.62736 12.5569C5.80649 12.7009 6.07175 12.5727 6.07175 12.342V5.26338C6.07175 5.03149 5.80462 4.90392 5.62611 5.04972L1.26585 8.61668ZM6.61788 12.2503C6.61788 12.4954 6.91247 12.6185 7.08474 12.4451L12.9854 6.5039C13.0921 6.39644 13.0921 6.22237 12.9854 6.1149L7.08474 0.173097C6.91247 -0.00034945 6.61788 0.122194 6.61788 0.367911V12.2503Z"
          fill="#1D2823"
        />
        <path
          d="M64.8163 10.7248V4.61769H66.0689V4.996C66.0689 5.37432 66.0658 5.64391 66.0571 5.80353C66.2406 5.39945 66.5165 5.07204 66.8847 4.81941C67.2604 4.55861 67.7323 4.42853 68.3002 4.42853C68.4681 4.42853 68.6304 4.4411 68.7896 4.46623V5.67785C68.4881 5.65271 68.2965 5.64014 68.2129 5.64014C67.5282 5.64014 66.9977 5.81296 66.6207 6.15734C66.2537 6.50235 66.0696 7.01955 66.0696 7.70956V10.7248H64.8169H64.8163Z"
          fill="#1D2823"
        />
        <path
          d="M60.3649 10.9265C59.4368 10.9265 58.7022 10.6406 58.1586 10.0687C57.6249 9.49681 57.3578 8.68928 57.3578 7.64609C57.3578 7.02331 57.4826 6.46841 57.7335 5.98075C57.9919 5.48429 58.3433 5.10157 58.7865 4.8326C59.2371 4.55484 59.742 4.41595 60.3025 4.41595C61.205 4.41595 61.9065 4.71069 62.4071 5.29953C62.9089 5.8802 63.1592 6.67076 63.1592 7.67185V7.93705H58.6229C58.6317 8.55103 58.7983 9.03869 59.1247 9.40066C59.4505 9.76201 59.8681 9.943 60.3774 9.943C60.7619 9.943 61.0877 9.85062 61.3554 9.66523C61.6307 9.48047 61.7973 9.19014 61.856 8.79486H63.1099C63.0425 9.40883 62.771 9.91786 62.2948 10.3213C61.8185 10.7254 61.1757 10.9271 60.3655 10.9271L60.3649 10.9265ZM61.8554 7.04028C61.8479 6.53565 61.7049 6.13157 61.4297 5.82867C61.1532 5.52576 60.7781 5.37431 60.3019 5.37431C59.8257 5.37431 59.4624 5.53393 59.1616 5.8538C58.8614 6.16488 58.6897 6.56016 58.6479 7.03965H61.8554V7.04028Z"
          fill="#1D2823"
        />
        <path
          d="M52.0688 13.3491L53.1335 10.5733L50.7275 4.61771H52.1187L53.5349 8.52906C53.6098 8.73078 53.6984 8.97901 53.7976 9.27375C53.8725 8.99598 53.9524 8.74775 54.0361 8.52906L55.3268 4.61771H56.6306L53.3969 13.3491H52.0688Z"
          fill="#1D2823"
        />
        <path
          d="M46.6082 10.9265C46.0146 10.9265 45.5134 10.7581 45.1046 10.4219C44.7033 10.0769 44.5023 9.61875 44.5023 9.04688C44.5023 7.96158 45.2251 7.31429 46.6712 7.10377L47.8608 6.93975C48.0949 6.90644 48.2572 6.84297 48.3489 6.75059C48.4494 6.64941 48.5006 6.51116 48.5006 6.33394C48.5006 6.03984 48.3914 5.80795 48.1748 5.64015C47.9657 5.46357 47.6823 5.37496 47.3216 5.37496C46.8959 5.37496 46.5614 5.47991 46.3192 5.69043C46.077 5.90095 45.9478 6.18689 45.9304 6.54824H44.6652C44.6908 6.15295 44.8069 5.79538 45.0154 5.47551C45.2332 5.14747 45.5334 4.89107 45.9179 4.70568C46.3023 4.51212 46.7455 4.41534 47.2461 4.41534C48.0737 4.41534 48.6909 4.62964 49.101 5.05886C49.5186 5.47928 49.7276 6.07691 49.7276 6.85051V10.7242H48.5499V10.4338C48.5499 10.3245 48.5574 10.1435 48.5755 9.89148C48.4163 10.2114 48.1654 10.4634 47.8234 10.6487C47.4895 10.8341 47.0844 10.9265 46.6082 10.9265ZM46.8591 9.96752C47.3353 9.96752 47.7273 9.82424 48.0368 9.53831C48.3539 9.2442 48.5131 8.86086 48.5131 8.39016V7.58263C48.4382 7.68381 48.2035 7.77179 47.8115 7.84783L47.0469 7.98671C46.6456 8.06275 46.3323 8.18467 46.107 8.35246C45.8904 8.51208 45.7812 8.73957 45.7812 9.03368C45.7812 9.32778 45.8817 9.5471 46.0814 9.7149C46.2911 9.88331 46.5501 9.96752 46.8591 9.96752Z"
          fill="#1D2823"
        />
        <path
          d="M43.7384 10.7248H37.7104V1.89282H39.0386V9.58921H43.7384V10.7248Z"
          fill="#1D2823"
        />
        <path
          d="M34.8955 10.826C34.1434 10.826 33.5586 10.645 33.141 10.2836C32.7235 9.91348 32.5144 9.33721 32.5144 8.55482V5.62759H30.7724V4.61833H32.5144V2.52377H33.7552V4.61833H35.7474V5.62759H33.7552V8.40337C33.7552 8.88286 33.8638 9.22347 34.081 9.42519C34.3063 9.62692 34.6702 9.7281 35.1714 9.7281C35.3967 9.7281 35.6101 9.71113 35.8105 9.67782V10.7122C35.5265 10.7883 35.2213 10.826 34.8955 10.826Z"
          fill="#1D2823"
        />
        <path
          d="M28.9561 1.89282V9.70295H30.1969V10.7248H26.2623V9.70295H27.716V2.91465H26.5126V1.89282H28.9561Z"
          fill="#1D2823"
        />
        <path
          d="M25.5046 10.7248H24.0635L23.2115 8.17587H19.828L18.9761 10.7248H17.5974L20.7299 1.89282H22.3589L25.5046 10.7248ZM20.2038 7.05286H22.8352L21.8078 3.97418L21.5195 3.00263C21.4277 3.35581 21.3316 3.68008 21.2311 3.97418L20.2038 7.05286Z"
          fill="#1D2823"
        />
        <path
          d="M1.04633 20.2317H8.89306C12.5043 20.2317 14.9479 21.9813 14.9479 25.2617C14.9479 27.7767 13.1285 29.3898 11.364 29.9095C13.3463 30.2652 14.5678 31.8231 14.5678 34.3111V39.3687H11.5538V34.8308C11.5538 32.1788 10.5764 31.304 7.99679 31.304H4.05969V39.3687H1.04633V20.2317ZM8.26891 28.7061C10.577 28.7061 11.8527 27.6673 11.8527 25.7261C11.8527 23.7849 10.5764 22.7737 8.26891 22.7737H4.06031V28.7061H8.26891Z"
          fill="#1D2823"
        />
        <path
          d="M17.4463 32.7525C17.4463 28.515 20.1888 25.672 24.0716 25.672C27.9544 25.672 30.6969 28.515 30.6969 32.7525C30.6969 36.99 27.9544 39.806 24.0716 39.806C20.1888 39.806 17.4463 37.0723 17.4463 32.7525ZM24.0716 37.5914C26.3522 37.5914 27.8183 35.7049 27.8183 32.7525C27.8183 29.8002 26.3247 27.9136 24.0716 27.9136C21.8184 27.9136 20.3517 29.7455 20.3517 32.7525C20.3517 35.7596 21.791 37.5914 24.0716 37.5914Z"
          fill="#1D2823"
        />
        <path
          d="M33.601 37.0717H36.7504V22.5273H34.1709V20.231H39.5472V37.0717H42.2354V39.368H33.601V37.0717Z"
          fill="#1D2823"
        />
        <path
          d="M45.0048 37.0717H48.1542V22.5273H45.5746V20.231H50.951V37.0717H53.6392V39.368H45.0048V37.0717Z"
          fill="#1D2823"
        />
        <path
          d="M56.7074 34.5021V26.1094H59.531V33.983C59.531 36.1699 60.373 37.5644 62.3278 37.5644C64.2826 37.5644 65.5047 36.0606 65.5047 33.6549V26.1094H68.3283V39.3686H65.5315V38.6849C65.5315 38.3022 65.559 37.8918 65.6133 37.5367C64.9342 38.8766 63.5499 39.806 61.5408 39.806C58.5811 39.806 56.708 37.9195 56.708 34.5021H56.7074Z"
          fill="#1D2823"
        />
        <path
          d="M72.4009 26.1094H75.2245V26.8748C75.2245 27.2305 75.2245 27.5585 75.1702 27.9683C75.9847 26.6561 77.4783 25.672 79.5142 25.672C83.1524 25.672 85.4336 28.6244 85.4336 32.7525C85.4336 36.8807 82.9083 39.806 79.4056 39.806C77.3691 39.806 76.0116 38.8766 75.1702 37.5368C75.2245 37.9741 75.2245 38.3298 75.2245 38.7126V45.0006H72.4009V26.11V26.1094ZM78.8358 37.5914C81.0621 37.5914 82.5282 35.5955 82.5282 32.7249C82.5282 29.8542 81.089 27.9136 78.8358 27.9136C76.5826 27.9136 75.0891 29.7725 75.0891 32.7249C75.0891 35.6772 76.6369 37.5914 78.8358 37.5914Z"
          fill="#1D2823"
        />
        <path
          d="M99.8507 20.2317H105.281C111.146 20.2317 114.811 23.2664 114.811 29.8272C114.811 35.5955 111.635 39.368 105.634 39.368H99.8507V20.231V20.2317ZM105.417 36.7984C109.218 36.7984 111.689 34.6931 111.689 29.7995C111.689 25.3433 109.652 22.7737 105.335 22.7737H102.892V36.7984H105.417Z"
          fill="#1D2823"
        />
        <path
          d="M117.418 35.7325C117.418 32.9442 119.59 31.8507 122.224 31.468L124.695 31.14C125.726 30.9759 126.08 30.5656 126.08 29.8278C126.08 28.652 125.157 27.8319 123.609 27.8319C121.763 27.8319 120.731 28.816 120.649 30.3199H117.771C117.907 27.7226 120.025 25.672 123.446 25.672C127.193 25.672 128.876 27.6949 128.876 31.0853V39.3686H126.243V38.7672C126.243 38.3569 126.27 37.9741 126.297 37.5644C125.645 38.9042 124.179 39.806 122.007 39.806C119.509 39.806 117.418 38.2752 117.418 35.7325H117.418ZM122.631 37.6731C124.613 37.6731 126.133 36.3063 126.133 34.2834V32.5885C125.943 32.8348 125.427 33.0259 124.613 33.1629L123.038 33.4639C121.436 33.7649 120.323 34.3387 120.323 35.6785C120.323 36.936 121.328 37.6744 122.631 37.6744L122.631 37.6731Z"
          fill="#1D2823"
        />
        <path
          d="M136.994 39.0676L131.781 26.1094H134.93L137.971 34.6114C138.134 35.1035 138.324 35.6232 138.487 36.2246C138.65 35.6232 138.813 35.1035 138.976 34.6114L141.773 26.1094H144.732L137.727 45H134.713L136.993 39.0676H136.994Z"
          fill="#1D2823"
        />
        <path
          d="M153.302 18.0008L155.235 21.5583L158.769 23.5046L155.235 25.4502L153.302 29.0077L151.37 25.4502L147.837 23.5046L151.37 21.5583L153.302 18.0008Z"
          fill="url(#paint0_linear_236_5662)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_236_5662"
          x1="147.837"
          y1="23.5046"
          x2="158.769"
          y2="23.5046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBD92" />
          <stop offset="1" stopColor="#89C9AB" />
        </linearGradient>
        <clipPath id="clip0_236_5662">
          <rect
            width="158"
            height="45"
            fill="white"
            transform="translate(0.768555)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
