import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { lumaHref } from '../../core/config';
import IconLink from '../icons/IconLink';
import IconLogoFull from '../icons/IconLogoFull';
import MobileHeaderMenu from './MobileHeaderMenu';

const Header: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <Stack direction="row" justifyContent="space-between" sx={{height:"max-content", position:"relative"}}>
      <Stack sx={{background: theme.colors.functional.container.default}} alignItems="center" justifyContent="space-between" py={2} px={{xs:2,md:4}} flex={1} direction="row">
        <RouterLink to="/"><IconLogoFull /></RouterLink>
        <MobileHeaderMenu />
      </Stack>
      <Stack direction="row" flex={{xs:0,sm:2}}>
        <Link flex={1} sx={{display: {xs:"none",sm:"flex"},px:4,alignItems:"center", background: theme.colors.functional.container.default}} component={RouterLink} to={location.pathname?.includes("past-events")? "/":"/past-events"}><Typography sx={{position:"relative",top:1.5}}>{location.pathname?.includes("past-events") ?"UPCOMING" : "PAST"} EVENTS</Typography></Link>
        <Link flex={1} sx={{display: {xs:"none",sm:"flex"}, px:2, gap:1, alignItems:"center", background: theme.colors.functional.container.default}} href={lumaHref} rel="noopener noreferrer" target="_blank">
          {/* <Box component="img" src="/imgs/luma.svg"/> */}
          <Typography sx={{position:"relative",top:1.5}}>REGISTER NOW</Typography>
          <Box component={IconLink} sx={{position:"relative", top: -1, width: 12, height: 12}}/>
        </Link>
      </Stack>
    </Stack>
  );
};

export default Header;
