import { Box, Grid, styled, Typography } from '@mui/material';
import { px } from '../../theme/box';

const Hightlight = styled(Typography)(({ theme } ) => ({
  background: '#5BBC90',
  color: '#000',
  fontSize: '43px',
  fontWeight: '500',
  lineHeight: 1.1,
  display: 'inline',
  [theme.breakpoints.up('sm')]: {
    fontSize: '54px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '112.941px',
  },
}));

export function Statistic() {
  return (
    <Grid container sx={{ background: '#FFF', flexDirection: {xs:"column-reverse", sm:"row"} }}>
      <Grid item container xs={12} sm={6} sx={{ position:"relative"}}>
          <Box width="100%" height="100%" sx={{minHeight:"40vh",background: "url('/imgs/brussels-event-day.jpg')", backgroundPosition:"bottom",backgroundSize:"cover",backgroundRepeat:'no-repeat'}} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box px={px} pt={{ md: 8.25, sm: 7, xs: 4 }} pb={{ xs: '56px', sm: '0px'}}>
          <Typography lineHeight={1.1} fontSize={{ sm: '42px', xs: '32px', md: '80px' }} >In Brussels,<br/> we welcomed over</Typography>
          <Box mt={{md: 6.25, xs: 2, sm: 3 }}>
            <Hightlight>1000+</Hightlight>
          </Box>
          <Typography mt={{ md: 2.5, sm: 1.5, xs: 1 }} variant='h5'>Attendees</Typography>
          <Box mt={{md: 6.25, xs: 2, sm: 3 }}>
            <Hightlight>37</Hightlight>
          </Box>
          <Typography mt={{ md: 2.5, sm: 1.5, xs: 1 }} mb={{xs:0, md:10}} variant='h5'>Industry leaders</Typography>
        </Box>
      </Grid>
      
    </Grid>
  );
}
